import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { isEmpty } from 'lodash';
import moment from 'moment';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Icon,
  IconButton,
  Grid,
  Link, Fab, Box, Divider, Tooltip, Drawer,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Close } from '@mui/icons-material';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import DomainIcon from '@mui/icons-material/Domain';
import PlaceIcon from '@mui/icons-material/Place';
import ReorderIcon from '@mui/icons-material/Reorder';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import { DialogStyles } from '../../css/dialogStyle';
import { formatPostShift, formatPostTitle, shiftIcon } from '../../utils/Functions';
import LoadingBar from './LoadingBar';
import { bgGray, navy, orange, orangeLight, sxStyles } from '../../css/style';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Popper from '@mui/material/Popper';
import DateUtilities from '../Datepicker/utils';
import Month from '../Datepicker/Month';

class ConfirmedShiftsDialog extends Component {
  constructor() {
    super();
    this.state = {
      calendarView: false,
      calendarShiftData: [],
      monthTitle: moment(new Date()).format('MMMM YYYY'),
      displayDate: new Date(),
    };
    this.fullCalendarRef = React.createRef();
  }

  renderMobileContent = () => {
    const { classes, confirmedShiftsData, handleClose, history } = this.props;
    const { calendarShiftData,calendarView } = this.state;
    const shiftData = calendarView ? calendarShiftData : confirmedShiftsData;
    if (!shiftData) return <LoadingBar size={20} noText />;
    if (isEmpty(shiftData)) {
      return (
        <Grid
          className={classes.infoGrid}
          direction="row"
          alignContent="space-between"
          container
        >
          <Grid item xs={12} style={{ minWidth: '360px' }}>
            <Typography>No shifts</Typography>
          </Grid>
        </Grid>
      );
    }

    return shiftData.map((item) => (
      <Grid
        className={classes.infoGrid}
        direction="row"
        alignContent="space-between"
        container
        key={`${item.id}_container`}
      >
        <Grid item xs={12} key={`${item.id}_date_container`}>
          <Typography
            key={`${item.id}_date`}
            color="secondary"
            className={classes.infoTypography}
          >
            <span>{`${item.intervalNumber ? `[${item.intervalNumber}]` : ''}`}</span>{' '}{`${moment(item.date).format("MM/DD/YYYY")}`}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          key={`${item.id}_practice_container`}
          className={classes.matchIteminfoItem}
        >
          <DomainIcon
            className={classes.linkIcon}
            key={`${item.id}_practice_icon`}
            fontSize="small"
          />
          <Typography key={`${item.id}_practice_name`}>
            {item.practiceName || "-"}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          key={`${item.id}_location_container`}
          className={classes.matchIteminfoItem}
        >
          <PlaceIcon
            className={classes.linkIcon}
            key={`${item.id}_location_icon`}
            fontSize="small"
          />
          <Typography key={`${item.id}_location`}>
            {item.location || "-"}
          </Typography>
        </Grid>
        {item.match_id && (
          <Grid
            item
            xs={12}
            key={`${item.id}_postlink_container`}
            className={classes.matchIteminfoItem}
          >
            <DoubleArrowIcon
              className={classes.linkIcon}
              fontSize="small"
              key={`${item.id}_postlink_icon`}
            />
            <Link
              href="#"
              className={classes.link}
              underline="always"
              key={`${item.id}_postlink`}
              onClick={() => thi.redirectToJobPosting(item)}
            >
              {formatPostTitle(item.jobPostingTitle)}
            </Link>
          </Grid>
        )}
        {item.match_id && item.status === "connected" && (
          <Grid
            item
            xs={12}
            key={`${item.id}_chatlink_container`}
            className={classes.matchIteminfoItem}
          >
            <Icon
              key={`${item.id}_chatlink_icon`}
              fontSize="small"
              className={classes.linkIcon}
            >
              chat_bubble
            </Icon>
            <Link
              href="#"
              className={classes.link}
              underline="always"
              key={`${item.id}_chatlink`}
              onClick={() => this.redirectToChat(item)}
            >
              Jump to Chat
            </Link>
          </Grid>
        )}
      </Grid>
    ));
  };

  renderDesktopContent = () => {
    const { classes, confirmedShiftsData, handleClose, width, history } = this.props;

    return (
      <div className={classes.dataGridContainer}>
        <DataGrid
          autoHeight
          rows={confirmedShiftsData}
          columns={[
            {
              field: "intervalNumber",
              headerName: "Interval",
              width: 150,
              renderCell: (params) => (
                <Typography variant="body2">{params.row.intervalNumber || ''}</Typography>
              ),
            },
            {
              field: "date",
              headerName: "Relief Date",
              width: 150,
              renderCell: (params) => (
                <Typography variant="body2">{`${moment(params.row.date).format(
                  "MM/DD/YYYY"
                )}`}</Typography>
              ),
            },
            {
              field: "practiceName",
              headerName: "Practice Location",
              flex: 1,
              maxWidth: 370,
              renderCell: (params) => (
                <div>
                  <Typography variant="body2" key={`practice_${params.row.id}`}>
                    {params.row.practiceName || "-"}
                  </Typography>
                  <Typography variant="body2" key={`location_${params.row.id}`}>
                    {`(${params.row.location})` || ""}
                  </Typography>
                </div>
              ),
            },
            {
              field: "jobPostingTitle",
              headerName: "Job Posting",
              flex: 0.7,
              renderCell: (params) => {
                if (params.row.match_id) {
                  return (
                    <Link
                      href="#"
                      className={`${classes.link} ${classes.overflowEllipsis}`}
                      underline="always"
                      key={`postlink_${params.row.id}`}
                      onClick={() => {
                        handleClose();
                        history.replace({
                          pathname: `/matches/${params.row.job_posting_id}`,
                        });
                      }}
                    >
                      {formatPostTitle(params.row.jobPostingTitle)}
                    </Link>
                  );
                }
                return null;
              },
            },
            {
              field: "chatLink",
              headerName: "Chat Link",
              type: "actions",
              flex: 0.5,
              renderCell: (params) => {
                if (params.row.match_id && params.row.status === "connected") {
                  return (
                    <Link
                      href="#"
                      className={classes.link}
                      underline="always"
                      key={`chatlink_${params.row.id}`}
                      onClick={() => {
                        handleClose();
                        history.replace({
                          pathname: `/messages/${params.row.match_id}`,
                        });
                      }}
                    >
                      Jump to Chat
                    </Link>
                  );
                }
                return null;
              },
            },
          ]}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          disableColumnMenu
          isRowSelectable={() => false}
          localeText={{ noRowsLabel: "No shifts" }}
        />
      </div>
    );
  };

  renderSwitchButton() {
    const {
      classes,
    } = this.props;
    const { calendarView } = this.state;
    const calendarIcon = <CalendarMonthIcon className={classes.matchIteminfoIcon} color={orange} />;
    const listIcon = <ReorderIcon className={classes.matchIteminfoIcon} />;
    return (
      <Fab
        variant="extended"
        className={classes.mapViewFabBtn}
        onClick={() => this.setState({ calendarView: !calendarView })}
      >
        {calendarView ? listIcon : calendarIcon}
        <Typography>{calendarView ? 'List View' : 'Calendar View'}</Typography>
      </Fab>
    );
  }

  renderEventContent(e) {
    const { classes } = this.props;
    const imageSrc = shiftIcon(moment(e.event.extendedProps.start_time));
    return <div className={classes.shiftEvent}>
      <img src={imageSrc} className={classes.shiftIcon} />
      <Typography component='span'>{e.event.title}</Typography>
    </div>;
  }

  renderItem = (item) => {
    const {
      classes, history,
    } = this.props;
    if(!item) return null;
    console.log(item)
    return (
      <>
      <div className={classes.drawerHeader}>
        <Typography color="secondary" sx={{ width: '50%' }}>
          {`${item.intervalNumber ? `[${item.intervalNumber}]` : ''} ${moment(item?.date).format('dddd, MMM D')}`}
        </Typography>
        <Divider sx={{ width: '50%' }} />
      </div>
      <div className={classes.drawerContainer}>
        <div className={classes.shiftContainer}>
          <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            <Typography>
            {formatPostTitle(item.jobPostingTitle)}
            </Typography>
            {item.start_time && (
              <img src={shiftIcon(moment(item.start_time))} style={{ width: '20px', height: '20px' }} />
            )}
          </div>
        </div>

        <Box className={classes.shiftContent}>
          <Tooltip title="Message" placement='top'>
            <IconButton onClick={() => this.redirectToChat(item)} disableRipple>
              <Icon sx={sxStyles.icon} style={{ color: orange }}>chat_bubble</Icon>
            </IconButton>
          </Tooltip>
          <div>
            <Link href="#" className={classes.link} underline="always" onClick={() => this.redirectToJobPosting(item)}>
              {item.practiceName}
            </Link>
            <Typography>({item.location})</Typography>
          </div>
        </Box>
      </div>
      </>
    );
  };

  renderCalendarHeader() {
    const {
      classes,
      isDesktop,
    } = this.props;
    const { monthTitle,displayDate } = this.state;

    if (isDesktop) {
      return (
        <Grid item xs={12} className={classes.shiftCalendarToolbar}>
          <div className={classes.titleTextDiv}>
            <Box className={classes.shiftCalendarMonthBtn}>
              <IconButton onClick={() => {
                this.fullCalendarRef?.current?.getApi()?.prev();
                this.setState({
                  monthTitle: this.fullCalendarRef?.current?.getApi()?.currentData?.viewTitle,
                  selectedShift: null
                });
              }} size="small">
                <ChevronLeftIcon style={{ fontSize: '16px' }}/>
              </IconButton>
            </Box>
            <Box className={classes.shiftCalendarMonthBtn}>
              <IconButton onClick={() => {
                this.fullCalendarRef?.current?.getApi()?.next();
                this.setState({
                  monthTitle: this.fullCalendarRef?.current?.getApi()?.currentData?.viewTitle,
                  selectedShift: null
                });
              }} size="small">
                <ChevronRightIcon style={{ fontSize: '16px' }}/>
              </IconButton>
            </Box>
            <Typography className={classes.shiftCalendarTitle}>{monthTitle}</Typography>
          </div>
        </Grid>
      );
    } else {
      return (
        <Grid item xs={12} container>
          <Grid item>
            <IconButton onClick={() => {
              const date = new Date(displayDate);
              date.setMonth(date.getMonth() - 1);
              this.setState({
                monthTitle: moment(date).format('MMMM YYYY'),
                displayDate: date,
              });
            }}>
              <ChevronLeftIcon />
            </IconButton>
          </Grid>
          <Grid item xs={10}>
            <Typography style={{textAlign: 'center'}}>{monthTitle}</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={() => {
              const date = new Date(displayDate);
              date.setMonth(date.getMonth() + 1);
              this.setState({
                monthTitle: moment(date).format('MMMM YYYY'),
                displayDate: date,
              });
            }}>
              <ChevronRightIcon/>
            </IconButton>
          </Grid>
        </Grid>
      );
    }
  }

  renderCalendarContent() {
    const {
      classes,
      confirmedShiftsData,
      isDesktop,
    } = this.props;
    const {
      monthTitle,
      selectedShift,
      displayDate,
    } = this.state;
    const events = confirmedShiftsData.map(shift => Object.assign({}, shift, { title: formatPostShift(shift.jobPostingTitle) }));

    if (isDesktop) {
      return (
        <FullCalendar
          ref={this.fullCalendarRef}
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          initialDate={new Date()}
          weekends
          events={events}
          eventMinHeight={30}
          contentHeight={500}
          eventContent={(e) => this.renderEventContent(e)}
          headerToolbar={{
            left: '',
            center: '',
            right: '',
          }}
          viewDidMount={(info) => {
            this.setState({ monthTitle: info?.view?.title });
          }}
          dateClick={(e) => {
            const shift = confirmedShiftsData.find(s => moment(s.date).format('YYYY-MM-DD') === moment(e.date).format('YYYY-MM-DD'));
            this.setState({ selectedShift: shift });
          }}
          eventClick={(e) => {
            this.setState({ selectedShift: e?.event?.extendedProps });
          }}
        />
      );
    }else{
      return (
        <>
          <Month
            displayDate={displayDate}
            selectedDates={[]}
            requestedDates={[]}
            confirmedDates={confirmedShiftsData.map(x=> DateUtilities.midDayDate(x.date))}
            onSelect={(date) => {
              this.setState({ calendarShiftData: confirmedShiftsData.filter(s => moment(s.date).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD')) })
            }}
            ref={this.fullCalendarRef}
            highlightedList={[]}
            surgeryNeededList={[]}
            boosted={false}
          />
          {this.renderMobileContent()}
        </>
      );
    }
  }

  renderCalendar() {
    const {
      classes,
      confirmedShiftsData,
      isDesktop,
    } = this.props;
    const { selectedShift } = this.state;
    const events = confirmedShiftsData.map(shift => Object.assign({}, shift, { title: formatPostShift(shift.jobPostingTitle) }));
    return (
      <div className={isDesktop?classes.dataGridContainer:classes.dataGridContainerMobile}>
        <Grid container className={classes.shiftCalendarTitleContainer}>
          {this.renderCalendarHeader()}
          <Grid item xs={12} container className={classes.shiftCalendarToolbar} spacing={1}>
            <Grid item xs={12} md={selectedShift ? 8 : 12}>
              {this.renderCalendarContent()}
            </Grid>
            <Grid item xs={12} md={selectedShift ? 4 : 0}>
              {this.renderItem(selectedShift)}
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }

  redirectToChat(item) {
    const {
      history,
      handleClose
    } = this.props;
    handleClose();
    window.location.assign(`/messages/${item.match_id}`);
    history.replace({ pathname: `/messages/${item.match_id}` });
  }

  redirectToJobPosting(item) {
    const {
      history,
      handleClose
    } = this.props;
    localStorage.setItem('viewDetailsMatchIdStr', item.match_id);
    handleClose();
    history.replace({
      pathname: `/matches/${item.job_posting_id}`,
    });
  }

  render() {
    const {
      classes,
      confirmedShiftsData = [],
      handleClose,
      open,
      isDesktop,
      history,
    } = this.props;
    const { calendarView } = this.state;
    return (
      <Dialog
        maxWidth="md"
        PaperProps={{
          style: {
            padding: "24px 4px",
          },
        }}
        open={open}
        onClose={() => handleClose()}
      >
        <DialogTitle className={classes.gridDialogContent}>
          <div className={classes.titleContainer}>
            {isDesktop && (
              <AssignmentTurnedInIcon className={classes.titleIcon} />
            )}
            <Typography color="primary" className={classes.title} style={{ marginTop: isDesktop ? 0 : '36px' }}>
              Confirmed Shifts
            </Typography>
          </div>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => handleClose()}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {
            calendarView ? this.renderCalendar() : (
              isDesktop
                ? this.renderDesktopContent()
                : this.renderMobileContent()
            )
          }
          {this.renderSwitchButton()}
        </DialogContent>
      </Dialog>
    );
  }
}

ConfirmedShiftsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
};

function ConfirmedShiftsDialogWrapper({ theme, ...rest }) {
  const isDesktop = theme ? useMediaQuery(theme.breakpoints.up('md')) : true;
  return <ConfirmedShiftsDialog {...rest} theme={theme} isDesktop={isDesktop} />;
}

export default withStyles(DialogStyles, { withTheme: true })(withRouter(ConfirmedShiftsDialogWrapper));
