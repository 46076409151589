import { navy, navyDark, orange, orangeDark, tiffany, gray, bgLightGray, fabBtn, bgGray, sxStyles } from './style';

const icon = {
  height: '50px',
  width: '50px',
  textAlign: 'center',
  margin: '20px 0',
  border: 'solid 3px #4bbdad',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#4bbdad',
};

export const DialogStyles = theme => ({
  checkBox: { paddingRight: 2 },
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
  dialogDescription: {
    fontFamily: 'Asap',
    fontSize: '14px',
    textAlign: 'center',
  },
  dialogDescriptionBold: {
    fontFamily: 'Asap',
    fontSize: '14px',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  badgeFilter: {
    color: '#fff',
    marginRight: 5,
    marginTop: 5,
    height: 25,
    padding: '0px 8px',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: 1,
    top: 1,
    color: theme.palette.grey[500],
  },
  searchFormCloseButton: {
    color: theme.palette.grey[500],
    width: '24px',
    height: '24px',
  },
  icon: {
    ...icon,
    padding: '12px',
  },
  refreshIcon: {
    height: '40px',
    width: '40px',
    textAlign: 'center',
    margin: '10px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#4bbdad',
  },
  whiskerIcon: {
    marginLeft: 8,
    width: '65px',
    height: '14px',
  },
  dialogActions: {
    justifyContent: 'center',
    padding: '8px 25px',
    margin: 0,
  },
  dialogContent: {
    padding: '8px 25px',
    maxWidth: '400px',
  },
  gridDialogContent: {
    padding: '8px 25px',
  },
  // textField: {
  //   '& label': {
  //     transform: 'translate(14px, 16px) scale(1)',
  //   },
  // },
  button: {
    backgroundColor: `${orange}`,
    color: 'white',
    width: '100%',
    margin: 0,
    padding: '14px',
    '&:hover': {
      backgroundColor: `${orangeDark}`,
    },
  },
  cancel: {
    backgroundColor: `${navy}`,
    width: '50%',
    '&:hover': {
      backgroundColor: `${navyDark}`,
    },
  },
  refreshButton: {
    width: '100%',
    height: '40px',
    textAlign: 'center',
    color: '#ffffff',
    borderRadius: '5px',
    backgroundColor: navy,

    '&:hover': {
      backgroundColor: `${navyDark}`,
    },
  },
  typographyName: {
    marginTop: '15px',
    fontWeight: 'bold',
  },
  formRow: {
    marginTop: 10,
    display: 'flex',
  },
  shiftIcon: {
    width: '16px',
    height: '16px',
  },
  shiftEvent: {
    display: 'flex',
    alignItems: 'center',
  },
  dataGridContainer: {
    minWidth: 900,
    width: '100%',

    '& .MuiDataGrid-cell': {
      '&:focus': {
        outline: 'none !important',
      },
      '&:focus-within': {
        outline: 'none !important',
      },
    },
    '& .MuiDataGrid-columnHeader': {
      backgroundColor: tiffany,
      color: '#fff',
      '&:focus': {
        outline: 'none !important',
      },
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 'bold',
    },
    '& .MuiDataGrid-sortIcon': {
      color: '#fff',
      fontWeight: 'bold',
    },
  },
  dataGridContainerMobile: {
    width: '100%',

    '& .MuiDataGrid-cell': {
      '&:focus': {
        outline: 'none !important',
      },
      '&:focus-within': {
        outline: 'none !important',
      },
    },
    '& .MuiDataGrid-columnHeader': {
      backgroundColor: tiffany,
      color: '#fff',
      '&:focus': {
        outline: 'none !important',
      },
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 'bold',
    },
    '& .MuiDataGrid-sortIcon': {
      color: '#fff',
      fontWeight: 'bold',
    },
  },
  shiftCalendarTitleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  shiftCalendarToolbar: {
    display: 'flex',

    [theme && theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  drawerHeader: {
    justifyContent: 'space-between',
    padding: '16px 10px',
    backgroundColor: bgGray,
    display: 'flex',
    marginTop: '24px',
  },
  shiftContainer: {
    ...sxStyles.eventDiv,
    backgroundColor: navy,
    border: `1px solid ${navy}`,
    borderRadius: '0px',
    cursor: 'pointer',
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '0px',
    padding: '20px 12px',
  },
  shiftContent:{
    margin: '0px 0px 10px 0px',
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    padding: '20px 12px',
    border: `1px solid ${navy}`,
    color: navy,
    justifyContent: 'space-between',
    '& .MuiButtonBase-root': { padding: '0 4px' },
  },
  titleTextDiv: {
    display: 'flex',
    alignItems: 'center',
  },
  shiftCalendarMonthBtn: {
    border: `1px solid ${tiffany}`,
    borderRadius: '50%',
    cursor: 'pointer!important',
    backgroundColor: 'white',
    boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.12)',
    justifyContent: 'center',
    alignItems: 'center',
    color: navy,
    display: 'flex',
    width: '20px',
    height: '20px',
    marginRight: '8px',
  },
  shiftCalendarTitle: {
    color: navy,
    padding: '0px 8px',
    fontSize: '20px',
    fontWeight: '700',
  },
  disabledRow: {
    color: gray,
  },
  titleIcon: {
    ...icon,
    margin: '16px 0',
    padding: '12px',
  },
  linkButton: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingLeft: '20px',
  },
  infoGrid: {
    width: '100%',
    backgroundColor: bgLightGray,
    borderRadius: 5,
    margin: '10px 0',
    padding: '10px',
  },
  infoTypography: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 'bold',
    color: orange,
  },
  matchIteminfoItem: {
    display: 'flex',
  },
  matchIteminfoIcon: {
    color: '#9e9e9e',
    marginRight: 5,
  },
  titleButton: {
    maxWidth: 510,
    overflowX: 'hidden',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 310,
    },
  },
  linkIcon: {
    color: gray,
    marginRight: 5,
  },
  matchItemInfoText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  overflowEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  mapViewFabBtn: {
    ...fabBtn,
    backgroundColor: orange,
    color: 'white',
    borderWidth: '1px',
    borderColor: orange,
    left: '28px',
    top: '20px',
    height: '40px',
    position: 'absolute',
    flexDirection: 'row',

    '& > * + *': {
      fontSize: '16px',
      fontWeight: 600,
    },

    '&:hover': {
      backgroundColor: orange,
    },

    // mobile styles
    [theme.breakpoints.down('md')]: {
      bottom: '70px',
    },
  },
  matchIteminfoIcon: {
    color: 'white',
    marginRight: 5,
  },
});

export const ResetPasswordDialogStyle = theme => ({
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  icon: icon,
  textFieldContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  textFieldVerificationCode: {
    margin: '15px 0',
    width: '47px',
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      display: 'none',
    },
  },
  textField: {
    width: '100%',
  },
  dialogActions: {
    justifyContent: 'center',
    padding: '8px 25px',
  },
  button: {
    backgroundColor: navy,
    color: 'white',
    width: '100%',
    padding: '14px',
    '&:hover': {
      backgroundColor: '#1c264c',
    },
  },
});