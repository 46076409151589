import moment from 'moment';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Button,
  IconButton,
  ButtonBase,
  Box,
  Grid,
  Fab,
  Alert,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { MessagesStyles } from '../Messages/styles/messagesStyle';
import { orange } from '../../css/style';
import PlaceIcon from '@mui/icons-material/Place';
import EventIcon from '@mui/icons-material/Event';
import PropTypes from 'prop-types';
import { formatPostTitle, getJobRoleText, getTimeCategory, shiftIcon } from '../../utils/Functions';
import LocationGoogleMap from './LocationGoogleMap';
import { IMG_STORAGE_LINK } from '../constants/Constants';

class JobDetailsDialog extends Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    const {
      classes,
      open = false,
      handleClose = () => {},
      handleTakeShift = () => {},
      matchData = {},
      selectedEvent = {},
    } = this.props;

    if (!matchData || !selectedEvent) return;

    let destination = matchData?.practice?.location;
    destination = destination?.replace(/\//g, '%2F');
    destination = destination?.replace(/:/g, '%3A');

    const { jobPosting, practice } = matchData;
    const isArchived = jobPosting && jobPosting.deletedAt && !jobPosting.isDeleted;
    const isDeleted = jobPosting && jobPosting.deletedAt && jobPosting.isDeleted;
    const jobRoleText = getJobRoleText(jobPosting?.talentType, matchData?.jobPostingCriteriaItems);
    const markers = practice ? [{ id: practice.id, name: practice.name, position: { lat: practice.lat, lng: practice.lng } }] : [];
    const shiftCat = selectedEvent.start && selectedEvent.end ? getTimeCategory(moment(selectedEvent.start)) : null;

    return (
      <Dialog
        maxWidth="md"
        PaperProps={{
          style: {
            padding: '0px 4px',
            width: '360px',
            height: '586px',
          },
        }}
        open={open}
        onClose={() => handleClose()}
      >
        <div className={classes.drawerHeader}>
          <Grid container className={classes.messagesHeader} style={{ height: '60px' }} >
            <Grid item xs={6}>
              Details
            </Grid>
            <Grid item xs={6}>
              <ButtonBase
                target='_blank'
                href={`https://www.google.com/maps/dir/?api=1&destination=${destination}`}
                className={classes.directionLink}
              >
                <Typography className={classes.directionLink}><PlaceIcon /> Get Directions</Typography>
              </ButtonBase>
            </Grid>
          </Grid>
        </div>
        <div style={{ maxHeight: '526px', overflowY: 'auto' }}>
          <div className={classes.popupDetailCard}>
            <div className={classes.titleContainer}>
              <Typography
                className={classes.jobTitle}
                style={{ maxWidth: '319px', wordBreak: 'break-all' }}
              >
                {formatPostTitle(matchData?.jobPosting?.title)}
              </Typography>
            </div>
            <div className={classes.shiftInfo}>
              <EventIcon className={classes.shiftInfoIcon} />
              <Typography component='span' className={classes.jobDescription}>{moment(selectedEvent?.date).format('MMM DD')}</Typography>
            </div>
            {selectedEvent.end && (
            <div className={classes.shiftInfo}>
              {shiftCat && (
                <img
                  src={shiftIcon(shiftCat)}
                  style={{ width: '20px', height: '20px', marginRight: '5px' }}
                />
              )}
              <Typography component='span' className={classes.jobDescription}>
                {`${moment(selectedEvent.start).format('h:mm a')} - ${moment(selectedEvent.end).format('h:mm a')}`.replace(':00','')}
              </Typography>
            </div>
            )}
          </div>
          {isArchived && (
            <Alert variant="filled" severity="warning">The underlying connected match is now archived</Alert>
          )}
          { isDeleted && (
              <Alert variant="filled" severity="error">The underlying connected match is now deleted</Alert>
          )}

          <div className={classes.popupDetailCard}>
            <Typography className={classes.sectionTitle}>Description</Typography>
            <Typography
              className={classes.jobDescription}
              dangerouslySetInnerHTML={{ __html: jobPosting && jobPosting.description }}
            />
            <Typography><strong>Role:</strong> {jobRoleText}</Typography>
          </div>

          <div className={classes.popupDetailCard}>
            <Grid container className={classes.locationTitle} style={{ marginTop: '0px'}}>
              <Grid item xs={6}>
                <Typography className={classes.sectionTitle}>Location</Typography>
              </Grid>
              <Grid item xs={6}>
                <ButtonBase
                  target='_blank'
                  href={`https://www.google.com/maps/dir/?api=1&destination=${destination}`}
                  className={classes.directionLink}
                >
                  <Typography className={classes.directionLink}><PlaceIcon /> Get Directions</Typography>
                </ButtonBase>
              </Grid>
            </Grid>
            <Typography className={classes.address}>{practice.address || `${practice.city} - ${practice.state} - ${practice.location}`}</Typography>
            <div className={classes.map}>
              <LocationGoogleMap
                markers={markers}
                containerStyle={{
                  padding: 0,
                  margin: 0,
                  with: '100%',
                  height: '100%',
                  overflowY: 'visible',
                }}
              />
            </div>
          </div>
        </div>
        <Fab
          variant="extended"
          className={classes.takeShipFabBtn}
          onClick={() => {
            handleTakeShift();
          }}
        >
          <EventIcon className={classes.takeShiftInfoIcon} color={orange} />
          <Typography>Take Shift</Typography>
        </Fab>

      </Dialog>
    );
  }
}

JobDetailsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default withStyles(MessagesStyles)(withRouter(JobDetailsDialog));
